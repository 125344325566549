import React, { useEffect, useState } from 'react';
import { YMaps, Map, FullscreenControl, Polyline, GeolocationControl, RulerControl, SearchControl, ZoomControl, TypeSelector } from "react-yandex-maps";


const YandexMap = (props) => {
    const mapData = {
        center: [52.254518, 77.051972],
        controls: ['geolocationControl'],
        zoom: 11,
        controls: []
      };
      
      const coordinates = [

        [52.299763, 77.047565],
        [52.297553, 77.067478], 
        [52.284501, 77.093227], 
        [52.280816, 77.101810], 
        [52.280079, 77.115887], 
        [52.277341, 77.122410], 
        [52.275762, 77.152450], 
        [52.251958, 77.154167], 
        [52.250062, 77.142837], 
        [52.229407, 77.144211], 
        [52.229407, 77.097862], 
        [52.223714, 77.082069], 
        [52.219286, 77.051857], 
        [52.228774, 77.023704], 
        [52.232990, 77.031601], 
        [52.236785, 77.023704], 
        [52.232147, 77.018555], 
        [52.241632, 76.999672], 
        [52.242686, 77.003448], 
        [52.250904, 76.992462], 
        [52.251115, 77.016494], 
        [52.266705, 77.014778], 
        [52.266495, 76.991088], 
        [52.283553, 77.002075], 
        [52.292817, 77.018898], 
        [52.299342, 77.038124], 
        [52.287554, 77.047050], 
        [52.298500, 77.047737], 
      ];
  
  const onLoadMap = (instance) => {
    console.log(instance);
    const location = instance.geolocation.get();
    console.log(location);
  }
  
    return (
      
        <>
        <YMaps query={{ apikey: '4a41a9ae-5889-4400-8d48-c285c890a61b' }}>
            <Map width={'100%'} height={700} defaultState={mapData}  modules={["geolocation", "geocode", "control.GeolocationControl"]}>
                {/* {coordinates.map(coordinate => <Placemark geometry={coordinate} />)} */}
                <Polyline
                    geometry={
                    // Specifying the coordinates of the vertices of the polyline.
                    coordinates
                    }/>
                    
                    <GeolocationControl />
                    <FullscreenControl />
                    <RulerControl />
                    <SearchControl options={{float: 'right'}} />
                    <ZoomControl />
                    <TypeSelector />
            </Map>
        </YMaps></>

    );
  };

export default React.memo(YandexMap);
