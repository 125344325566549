import React, { useEffect, useRef, useState } from 'react';
import s from './header.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import objToFd from '../../common/helpers/objToFd';
import beautyApi from '../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { 
    DisplayType, 
    DisplayType1,
    DisplayType2,
    DisplayType3,
    DisplayType4,
    DopDisplayType, 
    onAddAllServForClientReq, 
    onAddAllSDServsForClientReq,
    onAddServForClientReq_1, 
    onAddServForClientReq_2, 
    onAddServForClientReq_3, 
    onAddServForClientReq_4, 
    onAddServForClientReq_5 } from '../../redux/action';
import { ToastContainer, toast } from 'react-toast';
import Modal from '@mui/material/Modal';

const ChildSelectModal = (props) => {

    const dispatch = useDispatch();
    const { register, handleSubmit, reset } = useForm();
    const [open, setOpen] = React.useState(false);
    const itemsAllServForClientReq = useSelector((state) => state.itemsAllServForClientReq);
    const itemsAllSDServsForClientReq = useSelector((state) => state.itemsAllSDServsForClientReq);
    const handleOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};

    const [DopCatName1, setCatName1] = useState('Не выбрано');
    const [DopCatName2, setCatName2] = useState('Не выбрано');
    const [DopCatName3, setCatName3] = useState('Не выбрано');
    const [DopCatName4, setCatName4] = useState('Не выбрано');
    const [DopCatName5, setCatName5] = useState('Не выбрано');

    const [DopCatID1, setCatID1] = useState('-');
    const [DopCatID2, setCatID2] = useState('-');
    const [DopCatID3, setCatID3] = useState('-');
    const [DopCatID4, setCatID4] = useState('-');
    const [DopCatID5, setCatID5] = useState('-');

    const [dropDownDop, setDropDownDop] = useState(false);

    const itemsServForClientReq_1 = useSelector((state) => state.itemsServForClientReq_1);
    const itemsServForClientReq_2 = useSelector((state) => state.itemsServForClientReq_2);
    const itemsServForClientReq_3 = useSelector((state) => state.itemsServForClientReq_3);
    const itemsServForClientReq_4 = useSelector((state) => state.itemsServForClientReq_4);
    const itemsServForClientReq_5 = useSelector((state) => state.itemsServForClientReq_5);

    const display_flex1 = useSelector((state) => state.DisplayType1);
    const display_flex2 = useSelector((state) => state.DisplayType2);
    const display_flex3 = useSelector((state) => state.DisplayType3);
    const display_flex4 = useSelector((state) => state.DisplayType4);
    const dop_display_flex = useSelector((state) => state.DopDisplayType);

    const SecDopServs = (ids,  prod_id_add, prof_add_dop) => {
        return { 
          ids: ids,
          prod_id_add: prod_id_add,
          prof_add_dop: prof_add_dop,
          // ...другие свойства
        };}

    const DopServs = (ids,  profs) => {
      return { 
        ids: ids,
        profs: profs,
        // ...другие свойства
      };}

    useEffect(() => {
        {getDopServs();
        getSecondDopServs();} 
    }, [props.catID]);

    var warrObjects2 = [];
    const getDopServs = async () => {
        const responseAllDopServsForClient = await beautyApi.fetchServicesInReq2()

          for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
            if (responseAllDopServsForClient.data[i]['prof_id'] == props.catID) {
                let user = DopServs(responseAllDopServsForClient.data[i]['id'], responseAllDopServsForClient.data[i]['prof_add'])
                warrObjects2[i] = user;
            }};dispatch(onAddAllServForClientReq(warrObjects2));};

    var warrObjects3 = [];
    const getSecondDopServs = async () => {
      const responseAllDopServsForClient = await beautyApi.fetchServicesInReq3()

        for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
         
              let user = SecDopServs(responseAllDopServsForClient.data[i]['id'], responseAllDopServsForClient.data[i]['prod_id_add'], responseAllDopServsForClient.data[i]['prof_add_dop'])
              warrObjects3[i] = user;
          };dispatch(onAddAllSDServsForClientReq(warrObjects3));};

    const handleServ_1 = (event) => {
        dispatch(onAddServForClientReq_1(event.target.value));
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatName1(x.profs) : console.log(''))
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatID1(x.ids) : console.log(''))    
      }

    const handleServ_2 = (event) => {
        dispatch(onAddServForClientReq_2(event.target.value));
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatName2(x.profs) : console.log('')) 
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatID2(x.ids) : console.log(''))  
    }

    const handleServ_3 = (event) => {
        dispatch(onAddServForClientReq_3(event.target.value));
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatName3(x.profs) : console.log('')) 
    itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatID3(x.ids) : console.log(''))  
  }

    const handleServ_4 = (event) => {
        dispatch(onAddServForClientReq_4(event.target.value));
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatName4(x.profs) : console.log(''))   
    itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatID4(x.ids) : console.log(''))  
  }

    const handleServ_5 = (event) => {
        dispatch(onAddServForClientReq_5(event.target.value));
        itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatName5(x.profs) : console.log(''))  
    itemsAllServForClientReq.map((x) => event.target.value == x.profs ? setCatID5(x.ids) : console.log(''))  
  }

    const plusButton = () => {
      // setDropDownDop(!dropDownDop);
      console.log(itemsServForClientReq_1)
      itemsServForClientReq_1 !== '-' ? dispatch(DisplayType1('inline')) : console.log(display_flex1)
      itemsServForClientReq_2 !== '-' ? dispatch(DisplayType2('inline')) : console.log(display_flex2)
      itemsServForClientReq_3 !== '-' ? dispatch(DisplayType3('inline')) : console.log(display_flex3)
      itemsServForClientReq_4 !== '-' ? dispatch(DisplayType4('inline')) : console.log(display_flex4)
      itemsServForClientReq_4 !== '-' ? dispatch(DopDisplayType('none')) : console.log(display_flex4)

      // dispatch(DopDisplayType('none'));
    };

  return (

    <><span className={s.dropdown_dop_serv} onClick={handleOpen}>Выбрать услугу</span>
    <Modal
          open={open}
          onClose={handleClose}>
      <div className={s.modal_child}>
      <button className={s.close} onClick={handleClose}>
              &times;
      </button>
        <div className={s.content}>

            <select
              className={s.dropdown_serv}
              onChange={(e) => handleServ_1(e)}>
                <option selected="defaultValue">
                  {DopCatName1}
                </option>
                {itemsAllServForClientReq.map((x) => 
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>)}
            </select>

            <select
              className={s.dropdown_sec_serv}>
                {itemsAllSDServsForClientReq.map((x) => 
                {if (DopCatID1 == x.prod_id_add) {
                  return (<option key={x.ids} value={x.prof_add_dop}>
                    {x.prof_add_dop}
                  </option>)}
                })}
            </select>    

            <select
              style={{ display: `${display_flex1}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_2(e)}>
                <option selected="defaultValue">
                  {DopCatName2}
                </option>
                {itemsAllServForClientReq.map((x) => 
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>)}
            </select>

            <select
              style={{ display: `${display_flex1}` }}
              className={s.dropdown_sec_serv}>
                {itemsAllSDServsForClientReq.map((x) => 
                {if (DopCatID2 == x.prod_id_add) {
                  return (<option key={x.ids} value={x.prof_add_dop}>
                    {x.prof_add_dop}
                  </option>)}})}
            </select>  

            <select
              style={{ display: `${display_flex2}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_3(e)}>
                <option selected="defaultValue">
                  {DopCatName3}
                </option>
                {itemsAllServForClientReq.map((x) => 
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>)}
            </select>

            <select
              style={{ display: `${display_flex2}` }}
              className={s.dropdown_sec_serv}>
                {itemsAllSDServsForClientReq.map((x) => 
                {if (DopCatID3 == x.prod_id_add) {
                  return (<option key={x.ids} value={x.prof_add_dop}>
                    {x.prof_add_dop}
                  </option>)}})}
            </select>  

            <select
              style={{ display: `${display_flex3}` }}  
              className={s.dropdown_serv}
              onChange={(e) => handleServ_4(e)}>
                <option selected="defaultValue">
                  {DopCatName4}
                </option>
                {itemsAllServForClientReq.map((x) => 
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>)}
            </select>

            <select
              style={{ display: `${display_flex3}` }}
              className={s.dropdown_sec_serv}>
                {itemsAllSDServsForClientReq.map((x) => 
                {if (DopCatID4 == x.prod_id_add) {
                  return (<option key={x.ids} value={x.prof_add_dop}>
                    {x.prof_add_dop}
                  </option>)}})}
            </select>  

            <select
              style={{ display: `${display_flex4}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_5(e)}>
                <option selected="defaultValue">
                  {DopCatName5}
                </option>
                {itemsAllServForClientReq.map((x) => 
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>)}
            </select>

            <select
              style={{ display: `${display_flex4}` }}
              className={s.dropdown_sec_serv}>
                {itemsAllSDServsForClientReq.map((x) => 
                {if (DopCatID5 == x.prod_id_add) {
                  return (<option key={x.ids} value={x.prof_add_dop}>
                    {x.prof_add_dop}
                  </option>)}})}
            </select>  

            <div
              className={s.dop_section}
              style={{ display: `${dop_display_flex}` }}
              onClick={() => plusButton()}>
              {!dropDownDop ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="35"
                  fill="#653030"
                  className="bi bi-sliders"
                  viewBox="0 0 16 9">
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    fill="#ffffff"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"
                    fill="#404246"
                  />
                </svg>
              )}
            </div>

        </div>
        <button className={s.beauty_button_confirm} onClick={handleClose}>Принять</button>
      </div>
      </Modal></>

  );
};

export default ChildSelectModal;
